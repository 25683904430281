import axios from 'axios'
import lodash from 'lodash'
import { message } from 'antd'
import { TOKEN_KEY, ROLE_DATAPERMISSION_KEY, ROLE_CODE } from './consts'

const fetch = (options) => {
  let {
    method = 'get',
    data,
    url,
    responseType,
    contentType,
    body
  } = options
  const cloneData = lodash.cloneDeep(data)
  const token = window.localStorage.getItem(TOKEN_KEY) || ""
  const dataPermission = window.localStorage.getItem(ROLE_DATAPERMISSION_KEY) || "PERSONAL"
  const code = window.localStorage.getItem(ROLE_CODE) || ""
  if (token) {
    axios.defaults.headers.common['authorization'] = token
    axios.defaults.headers.common['data-permission'] = dataPermission
    if (code) {
      axios.defaults.headers.common['role'] = code
    }
  }

  switch (method.toLowerCase()) {
    case 'get':
      return axios.get(url, {
        params: {
          ...cloneData,
          t: new Date().getTime()
        },
        responseType: responseType
      })
    case 'delete':
      return axios.delete(url, {
        data: cloneData,
      })
    case 'post':
      if (contentType) {
        const headers = { 'Content-Type': contentType }
        return axios.post(url, cloneData, { responseType: responseType, headers })
      }
      if (body) {
        return axios.post(url, body)
      }
      return axios.post(url, cloneData, { responseType: responseType })
    case 'put':
      return axios.put(url, cloneData)
    case 'patch':
      return axios.patch(url, cloneData)
    default:
      return axios(options)
  }
}

export default function request(url, options) {
  const { silence = false, download = false, ...params } = options || {}
  return fetch({ url, ...params }).then((response) => {
    let resultData = response.data
    if (silence) {
      return Promise.resolve(resultData)
    }

    const { code, msg } = resultData

    if (download) {
      if (resultData.type === 'application/json') {
        //将blob转json
        let reader = new FileReader()
        reader.readAsText(resultData, 'utf-8')
        reader.onload = e => {
          let res = reader.result
          let resData = JSON.parse(res)
          if (resData.code === -1) {
            message.error(resData.msg)
            return Promise.resolve(resultData)
          }
        }
      } else {
        let fileName = response && response.headers && response.headers['content-disposition'] || ''
        let index = fileName.indexOf('FileName=')
        fileName = decodeURI(fileName.slice(index + 9)) || ''
        return Promise.resolve({ data: resultData, fileName })
      }
    }
    if (code === 401) {
      message.error('登录状态已失效，请重新登录')
      window.localStorage.removeItem(TOKEN_KEY)
      window.location.href = "/user/login"
      return Promise.resolve(resultData)
    } else if (code !== 0) {
      msg && message.error(msg)
      return Promise.resolve(resultData)
    }
    /* eslint-disable */
    return Promise.resolve(resultData)
  }).catch((error) => {
    /* eslint-disable */
    const { data, status } = error.response
    if (status === 401 || status === 403) {
      message.error('登录状态已失效，请重新登录')
      window.localStorage.removeItem(TOKEN_KEY)
      window.location.href = "/user/login"
      return
      // yield put(routerRedux.replace('/user/login'))
    } else if (!silence) {
      message.error(data.msg || data.message || "服务器异常")
    }
    return Promise.resolve({ ...data })
  })
}
