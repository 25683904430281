import { quertDictsItem } from '@/services/basicinfo'

export default {
  namespace: 'dict',
  state: {
    reserveStatus: [],
  },
  effects: {
    * queryReserveStatus({ payload = {} }, { put, call, select }) {
      const reservationStatus = yield select(_ => _.dict.reserveStatus)
      if (reservationStatus.length) return

      const { code, data } = yield call(quertDictsItem, { type: 'RESERVATION_STATUS', pageSize: 1000, pageNo: 1 })
      if (code === 0) {
        yield put({
          type: 'updateState',
          payload: {
            reserveStatus: data.rows,
          },
        })
      }
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload
      }
    }
  }
}
