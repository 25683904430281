import { query as queryUsers, queryCurrent, queryFrequentEmployee } from "@/services/user"
import { setAuthority } from '@/utils/authority'
import { fetchTriggerShared1, fetchTriggerShared2 } from '@/services/task'
import { ROLE_KEY, ROLE_CODE, CLINIC_KEY, CLINIC_LOGO } from '@/utils/consts'
import { get as SafeGet } from 'lodash'
import { message } from 'antd'

export default {
  namespace: "user",
  state: {
    list: [],
    currentUser: {},
    userAuthority: [],
    systemConfig: {},
    userPermissions: [],
    frequentEmployee: {},
  },
  effects: {
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers)
      yield put({
        type: "save",
        payload: response,
      })
    },
    *fetchFrequentEmployee({ payload = {} }, { call, put }) {
      const { data, code } = yield call(queryFrequentEmployee, payload)
      if (code == 0) {
        yield put({
          type: "updateState",
          payload: {
            frequentEmployee: data
          },
        })
      }
    },
    *fetchCurrent(_, { call, put }) {
      const { pathname } = location
      const { data, code } = yield call(queryCurrent)
      let userRole = []
      if (data.role === '在线医助') {
        userRole.push('assiant')
      } else if (data.role === '线下导诊') {
        userRole.push('guidance')
      } else {
        userRole.push('admin')
      }
      if (code === 0) {
        let { rolePermissionVos } = data
        let rols = localStorage.getItem(ROLE_KEY)
        //获取最新的logo
        let clinicId = localStorage.getItem(CLINIC_KEY)
        let logo = localStorage.getItem(CLINIC_LOGO)
        if (clinicId) {
          data && data.clinicResps.forEach((item) => {
            if (clinicId === `${item.id}`) {
              if (logo !== item.logo) {
                localStorage.setItem(CLINIC_LOGO, item.logo)
                return
              }
            }
          })
        }
        let codes = (rolePermissionVos.find(_ => _.roleId === Number(rols)) || {}).code
        const currentRoute = (rolePermissionVos.find(_ => _.roleId === Number(rols)) || {}).permissions
        localStorage.setItem(ROLE_CODE, codes)
        setAuthority(userRole)
        yield put({
          type: "updateState",
          payload: {
            userAuthority: userRole,
          },
        })
        yield put({
          type: "saveCurrentUser",
          payload: data,
        })
        if (data.clinicId) {
          sessionStorage.setItem('clinicId', data.clinicId)
        }

        // 如没有路由则跳转第一项下的路由
        // if (currentRoute && currentRoute.length > 0) {
        //   const isHave = currentRoute.findIndex(i => i.value == pathname)
        //   const link = SafeGet(currentRoute, '0.value')
        //   if (link !== pathname && isHave === -1) {
        //     console.log('跳转了')
        //     window.location.href = link
        //   }
        // }

      }
      // const resConfig = yield call(queryConfigs, { type: 'SYSTEM_CONFIG_ITEM' })
      // if(resConfig && resConfig.code === 0) {
      //   const configData = {}
      //   if(resConfig.data && resConfig.data.length > 0) {
      //     resConfig.data.forEach(_ => configData[_.refKey] = _.refValue)
      //   }

      //   yield put({
      //     type: "updateState",
      //     payload: {
      //       systemConfig: configData
      //     },
      //   })
      // }
      return {
        userRole
      }
    },
    * handleTriggerShared1({ payload = {} }, { put, call }) {
      const { code } = yield call(fetchTriggerShared1, payload)
      if (code === 0) {
        payload.isShared ? message.success('已分享') : message.success('已撤回')
      }
      return code
    },
    * handleTriggerShared2({ payload = {} }, { put, call }) {
      const { code } = yield call(fetchTriggerShared2, payload)
      if (code === 0) {
        payload.isShared ? message.success('已分享') : message.success('已撤回')
      }
      return code
    },
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.payload,
      }
    },
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
      }
    },
    saveUserAuthority(state, action) {
      return {
        ...state,
        userAuthority: action.payload || [],
      }
    },
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      }
    },
  },
}
